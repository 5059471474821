import React, { useState } from "react"
import {
  forgotPasswordRequest,
  forgotPasswordReset,
} from "../services/forgot-password"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import { FormProvider, useForm } from "react-hook-form"
import Input from "../components/form/Input"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { navigate } from "gatsby"
import SimpleIntro from "../components/global/simple-intro"
import FormWrapper from "../components/form/form-wrapper"
import Button from "../components/button/button"
import {toast} from 'react-toastify';

const ForgotPasswordRequestPage = ({
  component: Component,
  location,
  ...rest
}) => {
  return <ForgotPasswordForm />
}

const ForgotPasswordForm = ({ setIsChangePassword }) => {

  const [processing, setProcessing] = useState(false)
  const schema = yup
    .object({
      email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required"),
    })
    .required()

  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  const onSubmit = async (data) => {
    setProcessing(true)
    forgotPasswordRequest(data).then((res) => {
		if(res.status){
			navigate("/forgot-password-reset", { state: { email: data.email } })
		}else{
      setProcessing(false)
			toast.error(res.message)
		}
    })
  }

  return (
    <Layout whitePreFooter={true}>
      <Container gutters width={"xsmall"}>
        <SimpleIntro title={"Forgot Password"} />
        <FormWrapper
          heading={"Forgot Password"}
          text={
            "Enter your email address and we will send you a code to reset your password"
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Input
                label={"Email"}
                name="email"
                {...register("email")}
                required
                placeholder={"Your Email"}
                errorMessage={errors.email?.message}
              />
              <Container pt={1}>
                <Button primary disabled={processing} fullWidth type="submit">
                  {processing ? "Processing..." : "Request Password Reset"}
                </Button>
              </Container>
            </form>
          </FormProvider>
        </FormWrapper>
      </Container>
    </Layout>
  )
}

export default ForgotPasswordRequestPage
